import React, { Suspense, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from 'shared/components/header';
import LoadingScreen from 'shared/components/loading-screen'; // Assuming you might want to use this for a better loading UX
import Projects from 'shared/components/projects';
import ProjectsSelector from 'shared/components/projects/selector';
import { TabProvider } from 'shared/contexts/tabs-context';
import TCAcceptance from 'shared/components/tc-user-acceptance';
import useTermsAndConditions from 'hooks/useFetchTermsData';
import Logo from 'assets/Logo.png';
import Button from '@material-ui/core/Button';
import { SnipedFacetsProvider } from 'shared/contexts/sniped-facets-context';
import BarNotificationsController from './shared/components/notificationsController';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(React.lazy(() => import('pages/home-page')));
const ResultPage = Loadable(React.lazy(() => import('pages/result-page')));
const PdfPage = Loadable(React.lazy(() => import('pages/pdf-page')));

export default function Router() {
  const enableProjects = process.env.REACT_APP_ENABLE_PROJECTS === 'true';
  const enableTerms = process.env.REACT_APP_ENABLE_TERMS === 'true';
  const {
    termsSigned,
    termsID,
    termsMessage,
    fetchTermsAndConditions,
    isTermsLoading,
    termsError,
    loadTermsHasError,
  } = useTermsAndConditions();

  useEffect(() => {
    if (enableTerms) fetchTermsAndConditions();
  }, [enableTerms, fetchTermsAndConditions]);

  const Content = () => (
    <TabProvider>
      <Header />
      <BarNotificationsController />
      <SnipedFacetsProvider>
        <div className="container">
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/results" component={ResultPage} />
            <Route path="/pdfView" component={PdfPage} />
          </Switch>
        </div>
      </SnipedFacetsProvider>
      {enableProjects && <Projects />}
      {enableProjects && <ProjectsSelector />}
    </TabProvider>
  );

  const renderTermsError = (errorText: string) => (
    <>
      <TabProvider>
        <Header />
        <div className="termsLoading">
          <>
            <div>There has been an issue loading terms and conditions.</div>
            <div>{errorText}</div>
            <Button color="primary" type="button" onClick={() => window.location.reload()}>Click here to retry</Button>
          </>
        </div>
      </TabProvider>
    </>
  );

  const renderContent = () => {
    if (enableTerms && loadTermsHasError) {
      return renderTermsError(termsError.message.toString());
    }
    if (enableTerms && isTermsLoading) {
      // Render loading indicator when terms are being fetched
      return (
        <div className="termsLoading">
          <img alt="Loading Logo" src={Logo} className="Loading-Logo" />
          <div className="loading-text">Reviewing Terms and Conditions</div>
        </div>
      );
    }
    if (enableTerms && !termsSigned && !isTermsLoading) {
      if (termsID !== '0' && termsMessage !== null && termsMessage !== '') {
        return <TCAcceptance userAcceptance={termsSigned} fallbackMessage={termsMessage} tacID={termsID}><Content /></TCAcceptance>;
      }
      return renderTermsError('Incorrect terms and conditions configuration (empty). Please contact your administrator.');
    }
    // Handles all cases where terms are signed, not applicable, or loading has finished
    return <Content />;
  };

  return <div className="main-frame">{renderContent()}</div>;
}
