import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import { IAppState, IBarNotification } from 'shared/interfaces';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

export default function BarNotificationsController() {
  const searchResult = useSelector((state: IAppState) => state.barNotification);
  const dispatch = useDispatch();

  // Handle undefined
  const notifications = searchResult?.barNotifications || [];

  const handleCloseNotification = (notification: IBarNotification) => {
    dispatch(Actions.removeBarNotification(notification));
  };

  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          open={notification !== null} // This is to be sure that notification will be displayed while on the notifications list
          autoHideDuration={notification.duration || 6000}
          onClose={() => handleCloseNotification(notification)}
          anchorOrigin={notification.anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={notification.severity || 'info'}
          >
            {notification.message}
          </MuiAlert>
        </Snackbar>
      ))}
    </>
  );
}
