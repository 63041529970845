// interface IAppState is the base interface for react redux
export interface IAppState {
  toSearch: IToSearch;
  fieldsToSearch: IToSearchFields;
  searchResult: ISearchResult;
  projects: IProjects;
  pdfPreview: IPdfPreview;
  searchBody: ISearchBody;
  facetsConfiguration: IFacetsConfiguration;
  searchHighlightPreview:ISearchResultHighlightPreview;
  folderList?: IFolderConfiguration;
  selectedFolder?: ISelectedFolder;
  chatBody: IAskSearchBody;
  chatResult: IAskSearchResult;
  barNotification: IBarNotificationConfiguration;
}

export interface IToSearch {
  text: string;
}
export interface IToSearchFields {
  text: string;
}

// ********************
// INTERFACES FOR THE RETURN OF THE SEARCH API
// ********************
export interface ISearchResult {
  results: IResultItem[];
  facets: IFacets[];
  answers?: IAnswer[];
  total: number;
  isLoading?: boolean;
  error?: boolean;
  errorMessage?: string;
}

export interface IFacets {
  // this key is dynamic as the key of this object is dynamic
  [key: string]: IFacet;
}

export interface IFacet {
  count: number;
  keys: string[];
  values: any[];
  item: any;
  facetType: number;
  value: any;
  from: any;
  to: any;
}
export interface IAnswer {
  key: string;
  text: string;
  highlights: string;
  score: number;
}

export interface IResultItem {
  title: string;
  content: string;
  score: number;
  metadata?: IResultMetaData[];
  facets?: string[];
  path: string;
  storagePath: string;
  storageLastModified: string;
  hits: IResultItemHit[];
  fullHits: string[];
  searchTermsResults: any;
  searchTermsResultsOrder: number;
  channelName: string,
  groupName: string,
  from: string,
  id: string;
  summary: IResultSummaryItem[]
}

export interface IResultItemHit {
  text: string;
  occurrence: number;
}

export interface IResultSummaryItem {
  length: number;
  offset: number;
  rankScore: number;
  sentence: string;
}

export interface IResultMetaData {
  key: string;
  value: any;
}

// ********************
// INTERFACE FOR THE PDF PREVIEW ACTIONS
// ********************
export interface IPdfPreview {
  pdfURL?: string;
  pdfFileName?: string;
  showPdf: boolean;
  textToJumpTo?: string;
  shouldReload?: boolean;
  language?: string;
}

export interface ISearchBody {
  query: string;
  facets?: IFacets;
  filter?: string;
  startIndex?: number;
  resultsPerPage?: number;
  searchFields: string;

  top?: number;
  skip?: number;
  select?: string;
  searchMode?: string;
  queryType?: string;
  queryLanguage?: string;
  semanticConfiguration?: string;
  answers?: string;
  captions?: string;
}
export interface ISuggestBody {
  filter: string;
  search: string,
  searchFields: string,
  suggesterName: string,
  autocompleteMode: string,
}
export interface IRemoveBody {
  search: string;
  uniqueKeyField: string;
  uniqueValues: any[];
}

// ********************
// INTERFACE FOR THE FACETS CONFIGURATION
// ********************
export interface IFacetsConfiguration {
  facets: IFacetConfiguration[];
}

export interface IFacetConfiguration {
  key: string;
  valueToDisplay: string;
  type: string;
  count: number;
  sort: string;
}

// ********************
// INTERFACES FOR THE PROJECTS
// ********************

export interface IProjects {
  Username: string;
  active: boolean;
  NoOfProjects: number;
  Project: IProjectItem[];
  isLoading?: boolean;
  error?: boolean;
  errorMessage?: string;
  UIselectedProject?:IProjectItem;
  UIviewCollections?:boolean;
  UIviewQueries?:boolean;
  UIselectedCollection?:IProjectCollection;
  UIselectedQuery?:IProjectSavedQuery;
  UIselectedDocument?:IProjectCollectionDocument;
  UIopenProjectsSelector?: boolean;
  UIprojectsSelectorType?: 'document' | 'query';
  UIprojectsSelectorDocument?: IResultItem;
}

export interface IProjectItem {
  ProjectID: string;
  ProjectName: string;
  Description?: string;
  NoOfCollection: number;
  Collection: IProjectCollection[];
  NoOfSavedQueries: number;
  SavedQueries: IProjectSavedQuery[];
  Comment?: string;
  Shared: boolean;
}

export interface IProjectCollection {
  ProjectId: string;
  CollectionName: string;
  CollectionId?: string;
  Description: string;
  FileDataForCollection: IProjectCollectionDocument[];
}

export interface IProjectCollectionDocument {
  name: string;
  id: string;
  path: string;
  storagePath: string;
  summary: string;
  isDeleted?: boolean;
  comments: IProjectsCollectionDocumentComment[];
}

export interface IProjectsCollectionDocumentComment {
  CommentId: string;
  CommentByUserName: string;
  CommentByDisplayName: string;
  CommentCreateDateTime: Date;
  CommentModifiedDateTime: Date;
  CommentText: string;
  CommentUserId: string;
}

export interface IProjectSavedQuery {
  SavedQueryName: string;
  SavedQueryId?: string;
  Comment: string;
  Query: ISearchBody;
}

export interface IPosition {
  x: number;
  y: number;
}

export interface ISearchResultHighlightPreview {
  isVisible: boolean;
  currentHighlight: string;
}

// ********************
// INTERFACES FOR FOLDER FILTER
// ********************

export interface IFolder {
  folderName: string,
  level: number,
  open: boolean,
  children: IFolder[],
  path: string,
}
export interface IFolderConfiguration {
  isLoading: boolean,
  error: boolean,
  errorMessage: string,
  folder?: IFolder,
}
export interface ISelectedFolder {
  text: string;
}

// ********************
// INTERFACES FOR THE RETURN OF THE OPENAI ASK SEARCH API
// ********************

export interface ISearchModes {
  text: 'chat' | 'query' | 'ask';
}

export interface IAskSearchBody {
  query: string;
  filter: string;
  startIndex: number;
  resultsPerPage: number;
  searchFields: string;

  queryType?: string;
  queryLanguage?: string;
  semanticConfiguration?: string;
  answers?: string;
  captions?: string;

  openAIPrompt?: string;
  openAIMaxTokens?: number;
  openAIModel?: string;
  openAITemp?: number;
}

export interface IAskSearchResult {
  OpenAIAnswer: string;
  OpenAIAnswerType: 'chat' | 'cache' | undefined;
  Document?: IResultItem;
  isLoading: boolean;
  error?: boolean;
  errorMessage?: string;
  OpenAIAnswerProvided?: boolean;
}

export interface IURLParam {
  key: string;
  value: string
}

// ********************
// INTERFACES FOR THE RETURN OF THE OPENAI CHAT SEARCH API
// ********************
export enum IchatResultHistoryRole {
  User = 'user',
  Assistant = 'assistant',
  Tool = 'tool',
}
export interface IChatV2ResultSource {
  id: string;
  chunkId: string[];
  Name: string;
  Content: string;
}

export interface IChatResultSource {
  id: string;
  chunkId: string[];
  name: string;
  content: string;
}

export interface IToolCallFunction {
  arguments: string;
  name: string;
}
export interface IToolCall {
  id: string;
  function: IToolCallFunction;
  type: string;
  index: number;
}

export interface ICustomChatHistory {
  type: 'user' | 'assistant' ;
  message: string;
}
export interface ICustomChatBody {
  question: string;
  history?: ICustomChatHistory[];
}

export interface ICustomChatResponse {
  answer: string;
}

export interface IChatResultHistory {
  role: IchatResultHistoryRole;
  content: string;
  meta?: string;
  // eslint-disable-next-line camelcase
  tool_call_id?: string;
  // eslint-disable-next-line camelcase
  tool_calls?: IToolCall[];
}

export interface IChatUserMessage {
  question: string;
  date: Date;
}

export interface IChatCitation {
  // eslint-disable-next-line camelcase
  source_id: string;
  Position: number;
  // eslint-disable-next-line camelcase
  lead_white_space: number;
  ref?: number;
}

export interface IChatResultContentFilterResults {
  filtered: boolean;
  severity: string;
}
export interface IChatResultResponseMeta{
  question: string;
  // eslint-disable-next-line camelcase
  sim_score: number;
  // eslint-disable-next-line camelcase
  content_filter_results: { [key: string]: IChatResultContentFilterResults }
}

export interface IChatResultTokenUsage{
  // eslint-disable-next-line camelcase
  total_tokens_used: number;
  // eslint-disable-next-line camelcase
  prompt_tokens_used: number;
  // eslint-disable-next-line camelcase
  completion_tokens_used: number;
}
export interface IChatResult {
  // eslint-disable-next-line camelcase
  chat_id: string;
  question: string;
  answer: string;
  answerHtml: string;
  rephrasedAnswer: string;
  rephrasedQuestion: string;
  sources: IChatResultSource[];
  references: IChatResultSource[];
  chatHistory: IChatResultHistory[];
  newMessages: IChatResultHistory[];
  citations: IChatCitation[];
  followups: string[];
  tokenUsage: IChatResultTokenUsage;
  responseSource: string;
  responseMeta: IChatResultResponseMeta;
  finishReason: string;
  contentFilterResults: { [key: string]: IChatResultContentFilterResults }
  version: number;
}

export interface IChatResultV2 {
  question: string;
  response: string;
  sources: IChatResultSource[];
  references: IChatResultSource[];
  chatHistory: IChatResultHistory[];
  citations: IChatCitation[];
  tokenUsage: IChatResultTokenUsage;
  responseSource: string;
  responseMeta: IChatResultResponseMeta;
}

export interface IChatChatBodyHistory {
  messages: IChatResultHistory[];
  // eslint-disable-next-line camelcase
  new_messages?: IChatResultHistory[];
}
export interface IChatHistoryBody {
  // eslint-disable-next-line camelcase
  chat_id: string;
  question: string;
  documentIndexName?: string;
  chunkIndexName?: string;
  chatHistory: IChatChatBodyHistory;
  docIds?: string[];
}

export interface IChatBody {
  useSemCache?: boolean;
  search: ISearchBody;
  chat: IChatHistoryBody;
  save: boolean;
}

export interface UserData {
  allowance: number;
  identity: string;
  initialAllowance: number;
  lastModified: string;
}

export interface TCInterface {
  tacID: string;
  text: string;
  signed: boolean;
}

export interface TcPost {
  tacId: string;
  signed: boolean;
  date: Date;
}

// ********************
// INTERFACES FOR TRANSLATE SERVICE
// ********************

export interface ITranslateLanguage {
  name: string;
  code: string;
  isSource: boolean;
  isTarget: boolean;
}

// ********************
// INTERFACES FOR BAR NOTIFICATIONS
// ********************

export interface IBarNotificationConfiguration {
  barNotifications: IBarNotification[];
}

export interface IBarNotification {
  message: string;
  onClick?: () => void;
  duration: number;
  severity: IBarNotificationSeverity;
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}

export enum IBarNotificationSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

// ********************
// INTERFACES FOR DOWNLOAD SERVICE
// ********************

export interface IDownloadedBlob {
  error: boolean;
  errorMessage: string;
  contentTypeExtension: any;
  raw?: Blob;
}
