import { IBarNotificationConfiguration } from '../../shared/interfaces';

export const initialState: IBarNotificationConfiguration = {
  barNotifications: [],
};

const barNotification = (state: IBarNotificationConfiguration = initialState, action: any) => {
  switch (action.type) {
    case 'ADD_BAR_NOTIFICATION':
      return action.payload ? {
        ...state,
        barNotifications: [...state.barNotifications, action.payload],
      } : state;

    case 'REMOVE_BAR_NOTIFICATION':
      return action.payload ? {
        ...state,
        barNotifications: state.barNotifications.filter((notification) => notification.message !== action.payload.message),
      } : state;

    default:
      return state;
  }
};

export default barNotification;
