import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { apiLoginRequest, msalConfig } from 'authConfig';
import { IURLParam } from 'shared/interfaces';

export interface FetchResponse {
  error: boolean;
  errorMessage: string;
  statusCode: number | null;
  statusMessage: string | null;
  data?: any;
}

export interface FetchRawResponse {
  response?: Response;
  error: boolean;
  errorMessage: string;
  statusCode: number | null;
  statusMessage: string | null;
}

type HeadersObject = { [key: string]: string };

type IdTokenClaims = {
    roles?: string[];
};

type FetchOptions = {
    method?: string,
    queryParams?: IURLParam[],
    body?: any,
    headers?: HeadersObject,
    requiredRoles?: string[],
    defaultMessage?: string
};

async function parseResponse(fetchResponse: Response, isJson: boolean | null) {
  return isJson ? fetchResponse.json() : fetchResponse.text();
}

async function handleFetchResponse(fetchResponse: Response, defaultMessage: string) {
  const contentType = fetchResponse.headers.get('content-type');
  const isJson = contentType ? contentType.includes('application/json') : null;
  const data = await parseResponse(fetchResponse, isJson);

  return {
    error: !fetchResponse.ok,
    statusCode: fetchResponse.status,
    statusMessage: fetchResponse.statusText,
    errorMessage: !fetchResponse.ok && !isJson ? (data || defaultMessage) : (data?.message || defaultMessage),
    data: fetchResponse.ok ? data : undefined,
  };
}

export async function baseRawFetch(url: string, options: FetchOptions = {}): Promise<FetchRawResponse> {
  const {
    method = 'GET',
    queryParams = [],
    body = null,
    headers = {},
    requiredRoles = [],
    defaultMessage = 'Unexpected response from server.',
  } = options;

  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();
  const currentAccount = accounts[0];

  if (!currentAccount) {
    throw new Error('No accounts detected');
  }

  const response = await msalInstance.acquireTokenSilent({
    ...apiLoginRequest,
    account: currentAccount,
  });

  if (requiredRoles.length > 0) {
    if (
      !response?.account
      || !response.account?.idTokenClaims
      || !requiredRoles.some((role) => (response.account?.idTokenClaims as IdTokenClaims).roles?.includes(role))) {
      throw new Error('User does not have required role');
    }
  }

  const headersWithAuth : HeadersObject = {
    ...headers,
    Authorization: `Bearer ${response.accessToken}`,
  };

  const searchParams = new URLSearchParams();
  queryParams.forEach((param: IURLParam) => {
    searchParams.append(param.key, param.value);
  });
  const queryString = searchParams.toString();
  const urlWithParams = queryString ? `${url}?${queryString}` : url;

  try {
    const fetchResponse = await fetch(urlWithParams, { method, headers: headersWithAuth, body });
    return {
      error: false,
      errorMessage: '',
      statusCode: null,
      statusMessage: null,
      response: fetchResponse,
    };
  } catch (err: any) {
    if (err instanceof InteractionRequiredAuthError || err.errorCode === 'monitor_window_timeout') {
      msalInstance.acquireTokenRedirect({
        ...apiLoginRequest,
        account: currentAccount,
      });
    }
    return {
      error: true,
      errorMessage: err.message,
      statusCode: null,
      statusMessage: null,
    };
  }
}

export default async function baseFetch(url: string, options: FetchOptions = {}): Promise<FetchResponse> {
  const {
    defaultMessage = 'Unexpected response from server.',
  } = options;

  try {
    const rawResponse = await baseRawFetch(url, options);
    if (rawResponse.error) {
      return {
        error: true,
        errorMessage: rawResponse.errorMessage,
        statusCode: rawResponse.statusCode,
        statusMessage: rawResponse.statusMessage,
      };
    }
    return handleFetchResponse(rawResponse.response as Response, defaultMessage);
  } catch (err: any) {
    return {
      error: true,
      errorMessage: err.message,
      statusCode: null,
      statusMessage: null,
    };
  }
}
